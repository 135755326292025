// import js dependencies
import Lightbox from 'bs5-lightbox';

//swiper js
import Swiper from 'swiper/bundle';
//swiper css
import 'swiper/css/bundle';


jQuery(function($) {
	var buynow = false;

	//product swiper (must be before lightbox)
	var productSwiper = new Swiper(".productSwiper", {
		watchOverflow: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		allowTouchMove: true,
		preventInteractionOnTransition: true,
		breakpoints: {
			0: {
				slidesPerView: 3,
				spaceBetween: 10,
				direction: 'horizontal',
			},
			1280: {
				slidesPerView: 3,
				spaceBetween: 24,
				direction: 'vertical',
			},
			1400: {
				slidesPerView: 5,
				spaceBetween: 24,
				direction: 'vertical',
			}
		}
	});
	var productSwiper2 = new Swiper(".productSwiper2", {
		// allowTouchMove: false,
		watchOverflow: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		preventInteractionOnTransition: true,
		navigation: {
			nextEl: '.product-swiper-button-next',
			prevEl: '.product-swiper-button-prev',
		},
		pagination: {
			el: '.product-swiper-pagination',
			type: 'bullets',
		},
		effect: 'slide',
		thumbs: {
			swiper: productSwiper,
		},
		breakpoints: {
			0: {
				allowTouchMove: true,
				direction: 'horizontal',
			},
			1280: {
				allowTouchMove: false,
				direction: 'vertical',
			}
		}
	});


	var relatedSwiper = new Swiper(".relatedSwiper", {
		watchOverflow: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		preventInteractionOnTransition: true,
		spaceBetween: 100,
		navigation: {
			nextEl: '.related-swiper-button-next',
			prevEl: '.related-swiper-button-prev',
		},
		pagination: {
			el: '.related-swiper-pagination',
			type: 'bullets',
		},
		breakpoints: {
			0: {
				slidesPerView: 1,
				spaceBetween: 60,
			},
			// 500: {
			// 	slidesPerView: 2,
			// 	
			// },
			768: {
				slidesPerView: 2,
			},
			991: {
				slidesPerView: 3,
			}
		}
	});


	const headerSwiper = new Swiper("#headerSwiper.slider", {
        slidesPerView: 1,
		autoplay: true,
		effect: 'fade',
		loop: true,
		delay: 2000,
		speed: 1000,
    });

	//product modal
	const options = {
		keyboard: true,
		size: 'xl'
	};


	//reviews link to reviews
	$('body').on('click', '.ruk_rating_snippet', function(event){
		$('html, body').animate({
			scrollTop: $('.productDetailTabs').offset().top
		}, '200');
		$('#reviews-tab').click();
	});



	// js replace open and close canvas
	$('body').on('click', '.cartButton', function(event){
		event.preventDefault();
		$('#offcanvasScrolling').addClass('show')
		$('#offcanvasScrolling').removeClass('hiding')
	});

	$('body').on('click', '.closeMini', function(event){
		event.preventDefault();
		$('#offcanvasScrolling').addClass('hiding')
	});




	
	document.querySelectorAll('.lightbox-toggle:not(disabled)').forEach((el) => el.addEventListener('click', (e) => {
		e.preventDefault();

		const lightbox = new Lightbox(el, options);
		
		if($(el).hasClass('disabled')){
			// lightbox.show();
		}else{
			lightbox.show();
		}
		
	}));
	

	
	//menu
	$('body').on('click','.mobileMenu',function(){
		$('.menu .content').show();
		$('body').toggleClass('mobileOpen');
		$('.menu .content').toggleClass('open');
		$('.menuContainer').toggleClass('active');
		$('.menuContainer .menu').toggleClass('show');

		$(this).toggleClass('open');
	});


	var productSliderUpdate = true;

	const productCarousel = $('.productSwiper2'),
	swiperInstance = productCarousel.data('swiper');

	//close menu for desktop if resize is bigger than breakpoint


	if ($(window).width() < 768) {
		$('.lightbox-toggle').addClass('disabled')
	}


	$(window).on('resize', function(){
		var win = $(this); //this = window

		if (win.width() > 1200) {
			
			$('.menu .content.open').hide();

			$('#page').removeClass('mobileOpen');
			$('.menu .content').removeClass('open');
			$('.menuContainer').removeClass('active');
			$('.menuContainer .menu').removeClass('show');

			$('.mobileMenu').removeClass('open');
			
		}

		if(win.width() < 768){
			$('.lightbox-toggle').addClass('disabled')
		}else{
			$('.lightbox-toggle').removeClass('disabled')
		}
    })


	// get grand parent
	$.fn.gparent = function( recursion ){
		if( recursion > 1 ) return $(this).parent().gparent( recursion - 1 );
		return $(this).parent();
	};


	
	$('body').on('click', '.buyNow', function(event){
		event.preventDefault();
		var productID = $(this).attr('data-id');
		var cont = true;
		var type = $(this).attr('data-type');

		if(type == 'variant'){
			var classList = $(this).parent().attr('class').split(/\s+/);
			$.each(classList, function(index, item) {
				if (item === 'woocommerce-variation-add-to-cart-disabled') {
					alert('Please select some product options before adding this product to your basket.');
					cont = false;
				}
			});
		}
		
		var data = {
            action: 'woocommerce_ajax_add_to_cart',
            product_id: productID,
            product_sku: '',
            quantity: 1,
            variation_id: 0,
        };

		if(cont == true){

			$.ajax({
				type: 'post',
				url: wc_add_to_cart_params.ajax_url,
				data: data,
				beforeSend: function (response) {

				},
				complete: function (response) {

				}, 
				success: function (response) { 
					if (response.error) {
						buynow = false;
					} else { 
						buynow = true;
						$(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash]);
						//redirect to checkout
						// window.location.href = "/checkout";
					} 
				}, 
			}); 

		}
	});


	$('body').on( 'added_to_cart', function(){
		if(buynow == true){
			window.location.href = "/checkout";
		}
	});
	



	//woocommerce add to basket AJAX on product page

	$('body').on('click', '.ajaxSubmission .single_add_to_cart_button', function(event){
		event.preventDefault();
		buynow = false;
		var $thisbutton = $(this),
                $form = $thisbutton.closest('form.cart'),
                id = $thisbutton.val(),
                product_qty = $form.find('input[name=quantity]').val() || 1,
                product_id = $form.find('input[name=product_id]').val() || id,
                variation_id = $form.find('input[name=variation_id]').val() || 0;

    	var data = {
            action: 'woocommerce_ajax_add_to_cart',
            product_id: product_id,
            product_sku: '',
            quantity: product_qty,
            variation_id: variation_id,
        };

		var bool = true;

		//check if form is a variable product and that an ID is selected by choosing attribute options
		if($form.hasClass('variations_form')){
			if(variation_id == '0'){
				bool = false;
			}
		}

		if(bool == true){
			$.ajax({
				type: 'post',
				url: wc_add_to_cart_params.ajax_url,
				data: data,
				beforeSend: function (response) {
					$('.ajaxmessage').slideUp()
					$('.successmessage').slideUp()
					$thisbutton.removeClass('added').addClass('loading');
				},
				complete: function (response) {
					$thisbutton.addClass('added').removeClass('loading');
				}, 
				success: function (response) { 
					if (response.error) {
						$('.ajaxmessage').slideDown()
						$('.ajaxmessage').html(response.notices)
					} else { 
						if($thisbutton.gparent( 2 ).hasClass('inmodal')){
							$(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);
							$thisbutton.hide();
							var title = $thisbutton.gparent( 2 ).data('product')
							$('.successmessage span.title').html(title)
							$thisbutton.gparent( 2 ).append('<p class="addedtocart position-relative"><span class="addedSuccess"></span>Added to Cart</p>')
							$('.successmessage').slideDown()
						}else{
							$(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);
							$("#updateCartModal").modal('show');
							$('.addedtocart').remove()
							$('.inmodal .single_add_to_cart_button').show();
							var title = $form.parent().data('product')
							$('.successmessage span.title').html(title)
							$('.successmessage').slideDown()
						}
					} 
				}, 
			}); 

		}
	});
	


	//woocommerce mini cart

	$('body').on('click', '.updateQty', function(event){
		event.preventDefault();

		// var url = $(this).attr("data-url")+'/updatecart/';
		var key = $(this).attr("data-key");
		var qty = $(this).attr("data-qty");

		$(this).closest('.woocommerce-mini-cart-item').addClass('processing');
		

		$.ajax({
			type: 'POST',
			url: site_data.adminajax,
			data : {
				action: 'minicart_qty',
				key: key,
				qty: qty
			},
		}).done(function(response) {
			updateCartFragment();
			$(this).closest('.woocommerce-mini-cart-item').removeClass('processing');
		}).fail(function() {
			
		}).always(function() {
			
		})
		
	});

	//refresh mini cart if back button is used
	$(window).on('pageshow', function(e) {
		if (e.originalEvent.persisted) {
			setTimeout(function() {
				$('.widget_shopping_cart_content').empty();
				$(document.body).trigger('wc_fragment_refresh');
			}, 100);
		}
	});

	   
	function updateCartFragment() {
		var $fragment_refresh = '';
		var $supports_html5_storage = '';

		$fragment_refresh = {
			url: woocommerce_params.ajax_url,
			type: 'POST',
			data: { action: 'woocommerce_get_refreshed_fragments' },
			success: function( data ) {
			if ( data && data.fragments ) {          
				jQuery.each( data.fragments, function( key, value ) {
					jQuery(key).replaceWith(value);
				});
		
				if ( $supports_html5_storage ) {
					sessionStorage.setItem( "wc_fragments", JSON.stringify( data.fragments ) );
					sessionStorage.setItem( "wc_cart_hash", data.cart_hash );
				}                
				jQuery('body').trigger( 'wc_fragments_refreshed' );
			}
			}
		};
	   
		//Always perform fragment refresh
		jQuery.ajax( $fragment_refresh );  
	}





	//apply coupon ajax on checkout
	$('body').on('click', '#checkout_apply_coupon', function(event){
		event.preventDefault();

		var code = $( '#checkout_coupon_code').val();
		console.log(code);

		var button = $( this );

		button.val( 'Applying.');

		$.ajax({
			type: 'POST',
			url: site_data.adminajax,
			data : {
				action: 'coupon',
				coupon_code: code
			},
		}).done(function(response) {
			
			setTimeout(function(){
			//reload with ajax
			$(document.body).trigger('update_checkout');
				button.val( 'Apply Coupon');
			}, 2000);	
			
		}).fail(function() {
			
		}).always(function() {
			
		})


	});




	//cart plus and minus
	$(document).on( 'click', 'button.cartplus, button.cartminus', function() {
  
		var qty = $( this ).parent( '.quantity' ).find( '.qty' );
		var val = parseFloat(qty.val());
		var max = parseFloat(qty.attr( 'max' ));
		var min = parseFloat(qty.attr( 'min' ));
		var step = parseFloat(qty.attr( 'step' ));

		if ( $( this ).is( '.cartplus' ) ) {
		   if ( max && ( max <= val ) ) {
			  qty.val( max ).change();
		   } else {
			  qty.val( val + step ).change();
		   }
		} else {
		   if ( min && ( min >= val ) ) {
			  qty.val( min ).change();
		   } else if ( val > 1 ) {
			  qty.val( val - step ).change();
		   }
		}

		$("[name='update_cart']").trigger("click");

	 });

	

	$('body').on('click', '.searchButton', function(event){
		event.preventDefault();
		$('.hiddenSearchForm').toggleClass('open');
	});


	$('.woocommerce-MyAccount-navigation li.is-active').click(function(e) {
		if($('li.is-active').is(e.target)) {
			$(this).parent().toggleClass('open');
		}
	})


	//update price from variation choice
	$( ".single_variation_wrap" ).on( "show_variation", function ( event, variation ) {
        var sku = variation.sku;
        var price = variation.display_price;
        $('.priceSummary span').html(price);

        if(sku){
            $('.sku span').html(sku);
        }

        if(variation.availability_html){
            $('.updatedStock').html(variation.availability_html)
        }else{
			$('.updatedStock').html('In Stock')
		}

		console.log(variation.availability_html);
    });





	function billinhandshipping(){

		//for custom created fields

		$('#shipping_email').focusout(function(){
			$('#billing_email').val($('#shipping_email').val());
		});

		$('#shipping_phone').focusout(function(){
			$('#billing_phone').val($('#shipping_phone').val());
		});


		//fetchify lookup
		$('body').on('change', '#crafty_postcode_lookup_result_option2', function(event){
			event.preventDefault();
			var selectedValue = $(this).val();
			if (!$('#bill-to-different-address-checkbox').prop('checked')) {
				$('#billing_address_1').val(selectedValue);
			}
		});

		$('body').on('change', '#shipping_postcode', function(event){
			event.preventDefault();
			var selectedValue = $(this).val();
			if (!$('#bill-to-different-address-checkbox').prop('checked')) {
				$('#billing_postcode').val(selectedValue);
			}
		});

		$('body').on('change', '#shipping_city', function(event){
			event.preventDefault();
			var selectedValue = $(this).val();
			if (!$('#bill-to-different-address-checkbox').prop('checked')) {
				$('#billing_city').val(selectedValue);
			}
		});

		



		$(".woocommerce form.woocommerce-checkout .shipping_address :input").each(function(){

				var shippingFieldID = $(this).attr('id');

				$('#'+shippingFieldID).focusout(function(){
					if (!$('#bill-to-different-address-checkbox').prop('checked')) {
						var BillingFieldID = $(this).attr('id').replace("shipping", "billing");
						if($('#bill-to-different-address-checkbox').is(':checked')){
							if(shippingFieldID == 'shipping_state' || shippingFieldID == 'shipping_country'){
								$('#'+BillingFieldID).val($('#'+BillingFieldID).val()).change();
							}else{
								$('#'+BillingFieldID).val($('#'+BillingFieldID).val());
							}
						}else{
							if(shippingFieldID == 'shipping_state'){
								$('#'+BillingFieldID).val($(this).val()).change();
							}else if(shippingFieldID == 'shipping_country'){
								var billVal = $('#'+BillingFieldID).val();
								var shipVal = $('#'+shippingFieldID).val();
								if(shipVal != billVal){
									$('#'+BillingFieldID).val($(this).val()).change();
								}
							}else{
								$('#'+BillingFieldID).val($(this).val());
							}
							
						}
					}
				})
			
			
		});
	}

	billinhandshipping();
	

	$('body').on('change', '#bill-to-different-address-checkbox', function() {
		
		if(this.checked) {
			billinhandshipping();
			$('#ship-to-different-address-checkbox').prop('checked', true);
			$('.woocommerce-billing-fields').slideDown();
			$("#billing_first_name").val('');
			$("#billing_last_name").val('');
			$("#billing_address_1").val('');
			$("#billing_address_2").val('');
			$("#billing_city").val('');
			$("#billing_state").val('').change();;
			$("#billing_postcode").val('');
			$("#billing_country").val('').change();;
			$("#billing_company").val('');
			$("#billing_email").val($("#shipping_email").val());
			//$("#billing_email").val(''); We dont want to overwrite this as it is hidden
		}else{
			billinhandshipping();
			$('#ship-to-different-address-checkbox').prop('checked', false);
			$('.woocommerce-billing-fields').slideUp();
			$("#billing_first_name").val($("#shipping_first_name").val());
			$("#billing_last_name").val($("#shipping_last_name").val());
			$("#billing_address_1").val($("#shipping_address_1").val());
			$("#billing_address_2").val($("#shipping_address_2").val());
			$("#billing_city").val($("#shipping_city").val());
			$("#billing_state").val($("#shipping_state").val()).change();
			$("#billing_postcode").val($("#shipping_postcode").val());
			$("#billing_country").val($("#shipping_country").val()).change();;
			$("#billing_company").val($("#shipping_company").val());
			$("#billing_email").val($("#shipping_email").val());
		}
	});
});